import { default as aboutpQkmVZ5b2wMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsW8YQiY5aWYMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93YH9yb4Hob1Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45samplev18jacpATEMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsfWssHas6TSMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93C0ViOmqKVMMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as view7P4bfyQ7UyMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexiIwZo0yVnRMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93nUx29iLqitMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexXzs8ILP2hdMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93mBYNs0vI7nMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as index79xOd5Rv5AMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionszQZBQ8o1AjMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings4yXOIg38NqMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewswt1BkGS1ciMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93HMSQpcQYezMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexaFAo5wEWeiMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirm31joYX3hFdMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirm2YdaPeFmDmMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendarbo1ZQWy6CIMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/calendar.vue?macro=true";
import { default as chataaXptlLDmbMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutJc262AInnVMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedSOHwZyw0NxMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersXar7w89XUgMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93Bk1IU9OkHuMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45edit99p0ZkuuwMMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_937D2T6WnOBCMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communityF238M75AD8Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93LpiImIY6dqMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93MiawDC7gaiMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardGVd4gE1dfpMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexoXMY8IVfLyMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutvIM6a2NdSnMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45product6eNrTB4q0BMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventstMs3pLMTarMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93wG2iBwXDcaMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexl4z9YKrIKFMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_933H0BpzpAtgMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93EomX5sXmvRMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexUr4OHB45gqMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93NzdRnScYKQMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinzZiTVLQFt9Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/join.vue?macro=true";
import { default as loginFr1Pwb3MJDMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/login.vue?macro=true";
import { default as logout2nOAsryNaxMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93swQlKrszJ2Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93NdiU3qQuDsMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_937cTdBaUb1PMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesmHRqe67EGhMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosuC6x5SwUKMMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinHcrXKGEgY7Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_936m8v6uF9JpMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/podcasts/[podcast_id]/episode/[episode_id].vue?macro=true";
import { default as indexWr0JSz3xmMMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as index9Th8LJlPjjMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutA6GrdqTJUvMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as events6K20DmNikBMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93VJ3LSZZ61tMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleZCWi6C0Kv3Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsA4BmzIXxujMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93amNzSTQv9YMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewvaOwP3RrF7Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as index9pO2QD5hNNMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93aeNqvicAJAMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as index4lxpNtQ89ZMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93QVsUQkfeutMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as index2zhcpVrYovMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsAiuCJLh9SCMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsyQKAZES6o1Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsoY4rYvABZHMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93vRvb2h81ZfMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexWX51mEyR3HMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexGRjU5QYWg2Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesQa4Gx1R84jMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsmx9RdCUfwvMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93gYMFJSu0C2Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93f5fIZEC3aGMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetT2j649VzFFMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/reset.vue?macro=true";
import { default as shopy4782HH8DqMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/shop.vue?macro=true";
import { default as signupwgfe3ZsmS8Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/signup.vue?macro=true";
import { default as liveGnxsJbXzZHMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsDhtiJHOdcWMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93UjTozDi1vKMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactTpH2GGU4EeMeta } from "/tmp/build_2b732ccb/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as full53botSvFb8Meta } from "/tmp/build_2b732ccb/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93HMSQpcQYezMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93HMSQpcQYezMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93HMSQpcQYezMeta || {},
    alias: _91product_id_93HMSQpcQYezMeta?.alias || [],
    redirect: _91product_id_93HMSQpcQYezMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutpQkmVZ5b2wMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutpQkmVZ5b2wMeta?.path ?? "about",
    meta: aboutpQkmVZ5b2wMeta || {},
    alias: aboutpQkmVZ5b2wMeta?.alias || [],
    redirect: aboutpQkmVZ5b2wMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsW8YQiY5aWYMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsW8YQiY5aWYMeta?.path ?? "events",
    meta: eventsW8YQiY5aWYMeta || {},
    alias: eventsW8YQiY5aWYMeta?.alias || [],
    redirect: eventsW8YQiY5aWYMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93YH9yb4Hob1Meta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93YH9yb4Hob1Meta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93YH9yb4Hob1Meta || {},
    alias: _91media_id_93YH9yb4Hob1Meta?.alias || [],
    redirect: _91media_id_93YH9yb4Hob1Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45samplev18jacpATEMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45samplev18jacpATEMeta?.path ?? "free-sample",
    meta: free_45samplev18jacpATEMeta || {},
    alias: free_45samplev18jacpATEMeta?.alias || [],
    redirect: free_45samplev18jacpATEMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsfWssHas6TSMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsfWssHas6TSMeta?.path ?? "included-products",
    meta: included_45productsfWssHas6TSMeta || {},
    alias: included_45productsfWssHas6TSMeta?.alias || [],
    redirect: included_45productsfWssHas6TSMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93C0ViOmqKVMMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93C0ViOmqKVMMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93C0ViOmqKVMMeta || {},
    alias: _91media_id_93C0ViOmqKVMMeta?.alias || [],
    redirect: _91media_id_93C0ViOmqKVMMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: view7P4bfyQ7UyMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: view7P4bfyQ7UyMeta?.path ?? "lessons/:lesson_id()/view",
    meta: view7P4bfyQ7UyMeta || {},
    alias: view7P4bfyQ7UyMeta?.alias || [],
    redirect: view7P4bfyQ7UyMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexiIwZo0yVnRMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indexiIwZo0yVnRMeta?.path ?? "lessons",
    meta: indexiIwZo0yVnRMeta || {},
    alias: indexiIwZo0yVnRMeta?.alias || [],
    redirect: indexiIwZo0yVnRMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93nUx29iLqitMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93nUx29iLqitMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93nUx29iLqitMeta || {},
    alias: _91media_id_93nUx29iLqitMeta?.alias || [],
    redirect: _91media_id_93nUx29iLqitMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexXzs8ILP2hdMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexXzs8ILP2hdMeta?.path ?? "playlists/:playlist_id()",
    meta: indexXzs8ILP2hdMeta || {},
    alias: indexXzs8ILP2hdMeta?.alias || [],
    redirect: indexXzs8ILP2hdMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93mBYNs0vI7nMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93mBYNs0vI7nMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93mBYNs0vI7nMeta || {},
    alias: _91media_id_93mBYNs0vI7nMeta?.alias || [],
    redirect: _91media_id_93mBYNs0vI7nMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index79xOd5Rv5AMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: index79xOd5Rv5AMeta?.path ?? "playlists",
    meta: index79xOd5Rv5AMeta || {},
    alias: index79xOd5Rv5AMeta?.alias || [],
    redirect: index79xOd5Rv5AMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionszQZBQ8o1AjMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionszQZBQ8o1AjMeta?.path ?? "private-sessions",
    meta: private_45sessionszQZBQ8o1AjMeta || {},
    alias: private_45sessionszQZBQ8o1AjMeta?.alias || [],
    redirect: private_45sessionszQZBQ8o1AjMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings4yXOIg38NqMeta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordings4yXOIg38NqMeta?.path ?? "recordings",
    meta: recordings4yXOIg38NqMeta || {},
    alias: recordings4yXOIg38NqMeta?.alias || [],
    redirect: recordings4yXOIg38NqMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewswt1BkGS1ciMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewswt1BkGS1ciMeta?.path ?? "reviews",
    meta: reviewswt1BkGS1ciMeta || {},
    alias: reviewswt1BkGS1ciMeta?.alias || [],
    redirect: reviewswt1BkGS1ciMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexaFAo5wEWeiMeta?.name ?? "buy-product-product_id",
    path: indexaFAo5wEWeiMeta?.path ?? "/buy/product/:product_id()",
    meta: indexaFAo5wEWeiMeta || {},
    alias: indexaFAo5wEWeiMeta?.alias || [],
    redirect: indexaFAo5wEWeiMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirm31joYX3hFdMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirm31joYX3hFdMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirm31joYX3hFdMeta || {},
    alias: confirm31joYX3hFdMeta?.alias || [],
    redirect: confirm31joYX3hFdMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirm2YdaPeFmDmMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirm2YdaPeFmDmMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirm2YdaPeFmDmMeta || {},
    alias: confirm2YdaPeFmDmMeta?.alias || [],
    redirect: confirm2YdaPeFmDmMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendarbo1ZQWy6CIMeta?.name ?? "calendar",
    path: calendarbo1ZQWy6CIMeta?.path ?? "/calendar",
    meta: calendarbo1ZQWy6CIMeta || {},
    alias: calendarbo1ZQWy6CIMeta?.alias || [],
    redirect: calendarbo1ZQWy6CIMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chataaXptlLDmbMeta?.name ?? "chat",
    path: chataaXptlLDmbMeta?.path ?? "/chat",
    meta: chataaXptlLDmbMeta || {},
    alias: chataaXptlLDmbMeta?.alias || [],
    redirect: chataaXptlLDmbMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93Bk1IU9OkHuMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93Bk1IU9OkHuMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93Bk1IU9OkHuMeta || {},
    alias: _91group_id_93Bk1IU9OkHuMeta?.alias || [],
    redirect: _91group_id_93Bk1IU9OkHuMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutJc262AInnVMeta?.name ?? "community-groups-group_id-about",
    path: aboutJc262AInnVMeta?.path ?? "about",
    meta: aboutJc262AInnVMeta || {},
    alias: aboutJc262AInnVMeta?.alias || [],
    redirect: aboutJc262AInnVMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedSOHwZyw0NxMeta?.name ?? "community-groups-group_id-feed",
    path: feedSOHwZyw0NxMeta?.path ?? "feed",
    meta: feedSOHwZyw0NxMeta || {},
    alias: feedSOHwZyw0NxMeta?.alias || [],
    redirect: feedSOHwZyw0NxMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersXar7w89XUgMeta?.name ?? "community-groups-group_id-members",
    path: membersXar7w89XUgMeta?.path ?? "members",
    meta: membersXar7w89XUgMeta || {},
    alias: membersXar7w89XUgMeta?.alias || [],
    redirect: membersXar7w89XUgMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45edit99p0ZkuuwMMeta?.name ?? "community-profile-edit",
    path: community_45profile_45edit99p0ZkuuwMMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45edit99p0ZkuuwMMeta || {},
    alias: community_45profile_45edit99p0ZkuuwMMeta?.alias || [],
    redirect: community_45profile_45edit99p0ZkuuwMMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_937D2T6WnOBCMeta?.name ?? "community-profile-id",
    path: _91id_937D2T6WnOBCMeta?.path ?? "/community-profile/:id()",
    meta: _91id_937D2T6WnOBCMeta || {},
    alias: _91id_937D2T6WnOBCMeta?.alias || [],
    redirect: _91id_937D2T6WnOBCMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communityF238M75AD8Meta?.name ?? "community",
    path: communityF238M75AD8Meta?.path ?? "/community",
    meta: communityF238M75AD8Meta || {},
    alias: communityF238M75AD8Meta?.alias || [],
    redirect: communityF238M75AD8Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93LpiImIY6dqMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93LpiImIY6dqMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93LpiImIY6dqMeta || {},
    alias: _91uuid_93LpiImIY6dqMeta?.alias || [],
    redirect: _91uuid_93LpiImIY6dqMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93MiawDC7gaiMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93MiawDC7gaiMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93MiawDC7gaiMeta || {},
    alias: _91unique_id_93MiawDC7gaiMeta?.alias || [],
    redirect: _91unique_id_93MiawDC7gaiMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardGVd4gE1dfpMeta?.name ?? "dashboard",
    path: dashboardGVd4gE1dfpMeta?.path ?? "/dashboard",
    meta: dashboardGVd4gE1dfpMeta || {},
    alias: dashboardGVd4gE1dfpMeta?.alias || [],
    redirect: dashboardGVd4gE1dfpMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexoXMY8IVfLyMeta?.name ?? "event-details-event_id",
    path: indexoXMY8IVfLyMeta?.path ?? "/event/details/:event_id()",
    meta: indexoXMY8IVfLyMeta || {},
    alias: indexoXMY8IVfLyMeta?.alias || [],
    redirect: indexoXMY8IVfLyMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutvIM6a2NdSnMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutvIM6a2NdSnMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutvIM6a2NdSnMeta || {},
    alias: checkoutvIM6a2NdSnMeta?.alias || [],
    redirect: checkoutvIM6a2NdSnMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45product6eNrTB4q0BMeta?.name ?? "event-details-event_id-select-product",
    path: select_45product6eNrTB4q0BMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45product6eNrTB4q0BMeta || {},
    alias: select_45product6eNrTB4q0BMeta?.alias || [],
    redirect: select_45product6eNrTB4q0BMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventstMs3pLMTarMeta?.name ?? "events",
    path: eventstMs3pLMTarMeta?.path ?? "/events",
    meta: eventstMs3pLMTarMeta || {},
    alias: eventstMs3pLMTarMeta?.alias || [],
    redirect: eventstMs3pLMTarMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93wG2iBwXDcaMeta?.name ?? "gifts-uuid",
    path: _91uuid_93wG2iBwXDcaMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93wG2iBwXDcaMeta || {},
    alias: _91uuid_93wG2iBwXDcaMeta?.alias || [],
    redirect: _91uuid_93wG2iBwXDcaMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexl4z9YKrIKFMeta?.name ?? "gifts",
    path: indexl4z9YKrIKFMeta?.path ?? "/gifts",
    meta: indexl4z9YKrIKFMeta || {},
    alias: indexl4z9YKrIKFMeta?.alias || [],
    redirect: indexl4z9YKrIKFMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_933H0BpzpAtgMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_933H0BpzpAtgMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_933H0BpzpAtgMeta || {},
    alias: _91invitation_id_933H0BpzpAtgMeta?.alias || [],
    redirect: _91invitation_id_933H0BpzpAtgMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93EomX5sXmvRMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93EomX5sXmvRMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93EomX5sXmvRMeta || {},
    alias: _91product_id_93EomX5sXmvRMeta?.alias || [],
    redirect: _91product_id_93EomX5sXmvRMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexUr4OHB45gqMeta?.name ?? "index",
    path: indexUr4OHB45gqMeta?.path ?? "/",
    meta: indexUr4OHB45gqMeta || {},
    alias: indexUr4OHB45gqMeta?.alias || [],
    redirect: indexUr4OHB45gqMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93NzdRnScYKQMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93NzdRnScYKQMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93NzdRnScYKQMeta || {},
    alias: _91invitation_unique_id_93NzdRnScYKQMeta?.alias || [],
    redirect: _91invitation_unique_id_93NzdRnScYKQMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinzZiTVLQFt9Meta?.name ?? "join",
    path: joinzZiTVLQFt9Meta?.path ?? "/join",
    meta: joinzZiTVLQFt9Meta || {},
    alias: joinzZiTVLQFt9Meta?.alias || [],
    redirect: joinzZiTVLQFt9Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginFr1Pwb3MJDMeta?.name ?? "login",
    path: loginFr1Pwb3MJDMeta?.path ?? "/login",
    meta: loginFr1Pwb3MJDMeta || {},
    alias: loginFr1Pwb3MJDMeta?.alias || [],
    redirect: loginFr1Pwb3MJDMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logout2nOAsryNaxMeta?.name ?? "logout",
    path: logout2nOAsryNaxMeta?.path ?? "/logout",
    meta: logout2nOAsryNaxMeta || {},
    alias: logout2nOAsryNaxMeta?.alias || [],
    redirect: logout2nOAsryNaxMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93swQlKrszJ2Meta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93swQlKrszJ2Meta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93swQlKrszJ2Meta || {},
    alias: _91meeting_type_slug_93swQlKrszJ2Meta?.alias || [],
    redirect: _91meeting_type_slug_93swQlKrszJ2Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93NdiU3qQuDsMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93NdiU3qQuDsMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93NdiU3qQuDsMeta || {},
    alias: _91meeting_unique_id_93NdiU3qQuDsMeta?.alias || [],
    redirect: _91meeting_unique_id_93NdiU3qQuDsMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_937cTdBaUb1PMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_937cTdBaUb1PMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_937cTdBaUb1PMeta || {},
    alias: _91meeting_unique_id_937cTdBaUb1PMeta?.alias || [],
    redirect: _91meeting_unique_id_937cTdBaUb1PMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesmHRqe67EGhMeta?.name ?? "my-favorites",
    path: my_45favoritesmHRqe67EGhMeta?.path ?? "/my-favorites",
    meta: my_45favoritesmHRqe67EGhMeta || {},
    alias: my_45favoritesmHRqe67EGhMeta?.alias || [],
    redirect: my_45favoritesmHRqe67EGhMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosuC6x5SwUKMMeta?.name ?? "my-studios",
    path: my_45studiosuC6x5SwUKMMeta?.path ?? "/my-studios",
    meta: my_45studiosuC6x5SwUKMMeta || {},
    alias: my_45studiosuC6x5SwUKMMeta?.alias || [],
    redirect: my_45studiosuC6x5SwUKMMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinHcrXKGEgY7Meta?.name ?? "optin",
    path: optinHcrXKGEgY7Meta?.path ?? "/optin",
    meta: optinHcrXKGEgY7Meta || {},
    alias: optinHcrXKGEgY7Meta?.alias || [],
    redirect: optinHcrXKGEgY7Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_936m8v6uF9JpMeta?.name ?? "podcasts-podcast_id-episode-episode_id",
    path: _91episode_id_936m8v6uF9JpMeta?.path ?? "/podcasts/:podcast_id()/episode/:episode_id()",
    meta: _91episode_id_936m8v6uF9JpMeta || {},
    alias: _91episode_id_936m8v6uF9JpMeta?.alias || [],
    redirect: _91episode_id_936m8v6uF9JpMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/podcasts/[podcast_id]/episode/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexWr0JSz3xmMMeta?.name ?? "podcasts-podcast_id",
    path: indexWr0JSz3xmMMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexWr0JSz3xmMMeta || {},
    alias: indexWr0JSz3xmMMeta?.alias || [],
    redirect: indexWr0JSz3xmMMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index9Th8LJlPjjMeta?.name ?? "podcasts",
    path: index9Th8LJlPjjMeta?.path ?? "/podcasts",
    meta: index9Th8LJlPjjMeta || {},
    alias: index9Th8LJlPjjMeta?.alias || [],
    redirect: index9Th8LJlPjjMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93vRvb2h81ZfMeta?.name ?? "product-product_id",
    path: _91product_id_93vRvb2h81ZfMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93vRvb2h81ZfMeta || {},
    alias: _91product_id_93vRvb2h81ZfMeta?.alias || [],
    redirect: _91product_id_93vRvb2h81ZfMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutA6GrdqTJUvMeta?.name ?? "product-product_id-about",
    path: aboutA6GrdqTJUvMeta?.path ?? "about",
    meta: aboutA6GrdqTJUvMeta || {},
    alias: aboutA6GrdqTJUvMeta?.alias || [],
    redirect: aboutA6GrdqTJUvMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: events6K20DmNikBMeta?.name ?? "product-product_id-events",
    path: events6K20DmNikBMeta?.path ?? "events",
    meta: events6K20DmNikBMeta || {},
    alias: events6K20DmNikBMeta?.alias || [],
    redirect: events6K20DmNikBMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93VJ3LSZZ61tMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93VJ3LSZZ61tMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93VJ3LSZZ61tMeta || {},
    alias: _91media_id_93VJ3LSZZ61tMeta?.alias || [],
    redirect: _91media_id_93VJ3LSZZ61tMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleZCWi6C0Kv3Meta?.name ?? "product-product_id-free-sample",
    path: free_45sampleZCWi6C0Kv3Meta?.path ?? "free-sample",
    meta: free_45sampleZCWi6C0Kv3Meta || {},
    alias: free_45sampleZCWi6C0Kv3Meta?.alias || [],
    redirect: free_45sampleZCWi6C0Kv3Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsA4BmzIXxujMeta?.name ?? "product-product_id-included-products",
    path: included_45productsA4BmzIXxujMeta?.path ?? "included-products",
    meta: included_45productsA4BmzIXxujMeta || {},
    alias: included_45productsA4BmzIXxujMeta?.alias || [],
    redirect: included_45productsA4BmzIXxujMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93amNzSTQv9YMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93amNzSTQv9YMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93amNzSTQv9YMeta || {},
    alias: _91media_id_93amNzSTQv9YMeta?.alias || [],
    redirect: _91media_id_93amNzSTQv9YMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewvaOwP3RrF7Meta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewvaOwP3RrF7Meta?.path ?? "lessons/:lesson_id()/view",
    meta: viewvaOwP3RrF7Meta || {},
    alias: viewvaOwP3RrF7Meta?.alias || [],
    redirect: viewvaOwP3RrF7Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: index9pO2QD5hNNMeta?.name ?? "product-product_id-lessons",
    path: index9pO2QD5hNNMeta?.path ?? "lessons",
    meta: index9pO2QD5hNNMeta || {},
    alias: index9pO2QD5hNNMeta?.alias || [],
    redirect: index9pO2QD5hNNMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93aeNqvicAJAMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93aeNqvicAJAMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93aeNqvicAJAMeta || {},
    alias: _91media_id_93aeNqvicAJAMeta?.alias || [],
    redirect: _91media_id_93aeNqvicAJAMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index4lxpNtQ89ZMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: index4lxpNtQ89ZMeta?.path ?? "playlists/:playlist_id()",
    meta: index4lxpNtQ89ZMeta || {},
    alias: index4lxpNtQ89ZMeta?.alias || [],
    redirect: index4lxpNtQ89ZMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93QVsUQkfeutMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93QVsUQkfeutMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93QVsUQkfeutMeta || {},
    alias: _91media_id_93QVsUQkfeutMeta?.alias || [],
    redirect: _91media_id_93QVsUQkfeutMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index2zhcpVrYovMeta?.name ?? "product-product_id-playlists",
    path: index2zhcpVrYovMeta?.path ?? "playlists",
    meta: index2zhcpVrYovMeta || {},
    alias: index2zhcpVrYovMeta?.alias || [],
    redirect: index2zhcpVrYovMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsAiuCJLh9SCMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsAiuCJLh9SCMeta?.path ?? "private-sessions",
    meta: private_45sessionsAiuCJLh9SCMeta || {},
    alias: private_45sessionsAiuCJLh9SCMeta?.alias || [],
    redirect: private_45sessionsAiuCJLh9SCMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsyQKAZES6o1Meta?.name ?? "product-product_id-recordings",
    path: recordingsyQKAZES6o1Meta?.path ?? "recordings",
    meta: recordingsyQKAZES6o1Meta || {},
    alias: recordingsyQKAZES6o1Meta?.alias || [],
    redirect: recordingsyQKAZES6o1Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsoY4rYvABZHMeta?.name ?? "product-product_id-reviews",
    path: reviewsoY4rYvABZHMeta?.path ?? "reviews",
    meta: reviewsoY4rYvABZHMeta || {},
    alias: reviewsoY4rYvABZHMeta?.alias || [],
    redirect: reviewsoY4rYvABZHMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexWX51mEyR3HMeta?.name ?? "product",
    path: indexWX51mEyR3HMeta?.path ?? "/product",
    meta: indexWX51mEyR3HMeta || {},
    alias: indexWX51mEyR3HMeta?.alias || [],
    redirect: indexWX51mEyR3HMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexGRjU5QYWg2Meta?.name ?? "profile",
    path: indexGRjU5QYWg2Meta?.path ?? "/profile",
    meta: indexGRjU5QYWg2Meta || {},
    alias: indexGRjU5QYWg2Meta?.alias || [],
    redirect: indexGRjU5QYWg2Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesQa4Gx1R84jMeta?.name ?? "profile-purchases",
    path: purchasesQa4Gx1R84jMeta?.path ?? "/profile/purchases",
    meta: purchasesQa4Gx1R84jMeta || {},
    alias: purchasesQa4Gx1R84jMeta?.alias || [],
    redirect: purchasesQa4Gx1R84jMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsmx9RdCUfwvMeta?.name ?? "profile-redemptions",
    path: redemptionsmx9RdCUfwvMeta?.path ?? "/profile/redemptions",
    meta: redemptionsmx9RdCUfwvMeta || {},
    alias: redemptionsmx9RdCUfwvMeta?.alias || [],
    redirect: redemptionsmx9RdCUfwvMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93gYMFJSu0C2Meta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93gYMFJSu0C2Meta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93gYMFJSu0C2Meta || {},
    alias: _91quiz_id_93gYMFJSu0C2Meta?.alias || [],
    redirect: _91quiz_id_93gYMFJSu0C2Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93f5fIZEC3aGMeta?.name ?? "reset-password-uid-token",
    path: _91token_93f5fIZEC3aGMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93f5fIZEC3aGMeta || {},
    alias: _91token_93f5fIZEC3aGMeta?.alias || [],
    redirect: _91token_93f5fIZEC3aGMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetT2j649VzFFMeta?.name ?? "reset",
    path: resetT2j649VzFFMeta?.path ?? "/reset",
    meta: resetT2j649VzFFMeta || {},
    alias: resetT2j649VzFFMeta?.alias || [],
    redirect: resetT2j649VzFFMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopy4782HH8DqMeta?.name ?? "shop",
    path: shopy4782HH8DqMeta?.path ?? "/shop",
    meta: shopy4782HH8DqMeta || {},
    alias: shopy4782HH8DqMeta?.alias || [],
    redirect: shopy4782HH8DqMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupwgfe3ZsmS8Meta?.name ?? "signup",
    path: signupwgfe3ZsmS8Meta?.path ?? "/signup",
    meta: signupwgfe3ZsmS8Meta || {},
    alias: signupwgfe3ZsmS8Meta?.alias || [],
    redirect: signupwgfe3ZsmS8Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveGnxsJbXzZHMeta?.name ?? "streaming-event_id-live",
    path: liveGnxsJbXzZHMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveGnxsJbXzZHMeta || {},
    alias: liveGnxsJbXzZHMeta?.alias || [],
    redirect: liveGnxsJbXzZHMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsDhtiJHOdcWMeta?.name ?? "tips",
    path: tipsDhtiJHOdcWMeta?.path ?? "/tips",
    meta: tipsDhtiJHOdcWMeta || {},
    alias: tipsDhtiJHOdcWMeta?.alias || [],
    redirect: tipsDhtiJHOdcWMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93UjTozDi1vKMeta?.name ?? "waiver-uuid",
    path: _91uuid_93UjTozDi1vKMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93UjTozDi1vKMeta || {},
    alias: _91uuid_93UjTozDi1vKMeta?.alias || [],
    redirect: _91uuid_93UjTozDi1vKMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactTpH2GGU4EeMeta?.name ?? "widgets-calendars-compact",
    path: compactTpH2GGU4EeMeta?.path ?? "/widgets/calendars/compact",
    meta: compactTpH2GGU4EeMeta || {},
    alias: compactTpH2GGU4EeMeta?.alias || [],
    redirect: compactTpH2GGU4EeMeta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: full53botSvFb8Meta?.name ?? "widgets-calendars-full",
    path: full53botSvFb8Meta?.path ?? "/widgets/calendars/full",
    meta: full53botSvFb8Meta || {},
    alias: full53botSvFb8Meta?.alias || [],
    redirect: full53botSvFb8Meta?.redirect,
    component: () => import("/tmp/build_2b732ccb/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]